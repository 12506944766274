<template>
  <div class="addOperational">
    <el-form label-position="right" :model="ruleForm" ref="ruleForm" label-width="80px" class="demo-ruleForm">
      <el-form-item label="发布模块" prop="title">
        <!-- <el-select v-model="ruleForm.categoryId" filterable placeholder="发布模块">
          <el-option
            v-for="item in moduleData"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select> -->
        <el-cascader v-model="list" :options="operaMoudleData" :props="props" @change="handleChange"></el-cascader>
      </el-form-item>
      <el-form-item label="标题" prop="title">
        <el-input v-model="ruleForm.title" maxlength="100"></el-input >
      </el-form-item>
      <el-form-item label="排序" prop="sort">
        <el-input v-model="ruleForm.sort" maxlength="3"></el-input ><span class="art-tips">注：文章根据排序的序号倒序排列。</span>
      </el-form-item>
      <el-form-item label="内容" prop="body">
        <!-- <vue-ueditor-wrap v-model="ruleForm.body" :config="myConfig" :destroy="true" @ready="ready" @beforeInit="addCustomButtom"></vue-ueditor-wrap> -->
        <vueEditor :editorContent.sync="ruleForm.body"></vueEditor>
      </el-form-item>
    </el-form>
    <div class="opera-btn">
      <el-button type="primary" @click="addEvent">保存并发布</el-button>
      <!-- <el-button @click="goBack">返回</el-button> -->
    </div>
    <input style="display: none;" type="file" @change="picChange()" ref="loadPic" accept=".jpg,.png" />
  </div>
</template>

<script>
// import VueUeditorWrap from 'vue-ueditor-wrap'
import vueEditor from '../commonModule/vueEditor'
export default {
  name: 'addOperational',
  components: {
    vueEditor
    // VueUeditorWrap
  },
  data() {
    return {
      list: [],
      moduleData: {},
      ruleForm: {
        title: null,
        body: null,
        sort: 0,
        categoryId: null
      },
      urlType: '',
      // editConfig: {
      //   zIndex: 1,
      //   // 编辑器不自动被内容撑高
      //   autoHeightEnabled: false,
      //   // 初始容器高度
      //   initialFrameHeight: 300,
      //   // 初始容器宽度
      //   initialFrameWidth: '100%',
      //   // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
      //   // serverUrl: 'http://35.201.165.105:8000/controller.php',
      //   // UEditor 资源文件的存放路径，如果你使用的是 vue-cli2.0 生成的项目，通常不需要设置该选项，默认是：UEDITOR_HOME_URL: '/static/UEditor/'
      //   //但是 vue cli 3.x 生成的项目中，默认值是 process.env.BASE_URL + 'UEditor/'，如果项目不是部署在根目录下，则需要自己配置路径，我的项目是在根目录下，手动下载的UEditor放在了public目录下
      //   UEDITOR_HOME_URL: '/UE/',
      // },
      props: {
        value: 'id', 
        label: 'name',
        lazy: true,
        lazyLoad: this.lazyLoad
      },
      operaMoudleData: [],
    }
  },
  created() {
    let query = this.$route.query;
    if(query.status == 'edit') {
      this.watchArticleData(query.id);
      return;
    }
    this.getColumnEvent(1);
  },
  methods: {
    lazyLoad(node, resolve) {
      const { level, value } = node;
      let id;
      if(this.$route.query.status == 'edit') {
        if(level == 0) {
          id = 1;
        } else {
          id = this.list[0];
        }
        setTimeout(() => {
          this.$request.getColumn({id}, res => {
            if(res.code == 0) {
              const nodes = res.data
                .map(item => ({
                  id: item.id,
                  name: item.name,
                  leaf: level >= 1
                }));
              // 通过调用resolve将子节点数据返回，通知组件数据加载完成
              resolve(nodes);
            }
          })
        }, 1000);
      } else {
        if(level == 0) return;
        setTimeout(() => {
          this.$request.getColumn({id: value}, res => {
            if(res.code == 0) {
              const nodes = res.data
                .map(item => ({
                  id: item.id,
                  name: item.name,
                  leaf: level >= 1
                }));
              // 通过调用resolve将子节点数据返回，通知组件数据加载完成
              resolve(nodes);
            }
          })
        }, 1000);
      }
    },
    // 获取栏目
    getColumnEvent(id) {
      this.$request.getColumn({id}, res => {
        if(res.code == 0) {
          this.operaMoudleData = res.data;
        }
      })
    },
    handleChange(value) {
      // console.log(value);
      this.ruleForm.categoryId = value[1];
    },
    // 通过id获取文章
    watchArticleData(id) {
      this.$request.watchArticle({id}, res => {
        if(res.code == 0) {
          this.ruleForm.title = res.data.title;
          this.ruleForm.body = res.data.body;
          this.ruleForm.sort = res.data.sort;
          this.ruleForm.categoryId = res.data.categoryId;
          this.getColumnDetailsEvent(this.ruleForm.categoryId);
        }
      })
    },
    // 获取栏目详情
    getColumnDetailsEvent(id) {
      this.$request.getColumnDetails({id}, res => {
        if(res.code == 0) {
          this.list = [res.data.parentId, id];
        }
      })
    },
    // 保存
    addEvent() {
      if(!this.ruleForm.categoryId) {
        this.Warn('发布模块未选');
        return;
      }
      if(!this.ruleForm.title || !this.ruleForm.body || !this.ruleForm.categoryId) {
        this.Warn('标题或内容未填');
        return;
      }
      if(this.$route.query.status == 'add') {
        this.$request.addArticle(this.ruleForm, res => {
          if(res.code == 0) {
            this.Success(res.msg);
            this.$router.go(-1)
          }
        })
      } else {
        let obj = JSON.parse(JSON.stringify(this.ruleForm));
        obj.id = this.$route.query.id;
        this.$request.editArticleById(obj).then(res => {
          if(res.data.code == 0) {
            this.Success(res.data.msg)
            this.$router.go(-1)
          }
        })
      }
    },
    ready(editorInstance) {
      // console.log(`编辑器实例${editorInstance.key}: `, editorInstance)
    },
    addCustomButtom(editorId) {
      var that = this;
      window.UE.registerUI('simpleupload insertvideo', function (editor, uiName) {
        // 注册按钮执行时的 command 命令，使用命令默认就会带有回退操作
        editor.registerCommand(uiName, {
          execCommand: function () {
            editor.execCommand('inserthtml', ``);
          }
        })
        let btn = new window.UE.ui.Button({
          // 按钮的名字
          name: uiName,
          // 提示
          title: uiName == 'simpleupload' ? '单图上传' : '视频上传',
          // 点击时执行的命令
          onclick: function () {
            let upLoadType = uiName == 'simpleupload' ? 'img' : 'video';
            that.editorModule = editor;
            that.getLocalPic(upLoadType);
          }
        })

        // 当点到编辑内容上时，按钮要做的状态反射
        editor.addListener('selectionchange', function () {
          // var state = editor.queryCommandState(uiName);
          // if (state === -1) {
          //   btn.setDisabled(true);
          //   btn.setChecked(false);
          // } else {
          //   btn.setDisabled(false);
          //   btn.setChecked(state);
          // }
        })

        // 因为你是添加 button，所以需要返回这个 button
        return btn;
      }, 46 /* 指定添加到工具栏上的哪个位置，默认时追加到最后 */, editorId /* 指定这个 UI 是哪个编辑器实例上的，默认是页面上所有的编辑器都会添加这个按钮 */)
    },
    getLocalPic(type) {
      this.urlType = type;
      this.$refs.loadPic.value= '';
      this.$refs.loadPic.click();
    },
    picChange() {
      let inputDOM = this.$refs.loadPic;
      let files = inputDOM.files[0];
      let isFileType = files.type
      // if(isFileType != 'image/png' || isFileType != 'image/jpeg') {
      //   this.Warn('上传图片只能是 PNG 或 JPG 格式!');
      //   return;
      // }
      if (files.name.indexOf('+') != -1) {
        this.Warn('文件名内不能带有+号');
        return;
      }
      let that = this;
      var reader = new FileReader();
      reader.onload = function(e) {
        var data = e.target.result;
        //加载图片获取图片真实宽度和高度
        var image = new Image();
        image.onload=function(){
            var width = image.width;
            var height = image.height;
            // if(width != 500 && height != 500) {
            //   that.Warn('上传图片的尺寸只能为500X500')
            //   return;
            // }
            that.upLoadEvent(files);
        };
        image.src = data;
      };
      reader.readAsDataURL(files);
    },
    async upLoadEvent(file) {
      // let ossUrl = await way.uploadOss(OSS, file, 'img/addStudent');
      this.$request.uploadPic(file, res=>{
        if(res.code == 0) {
          let ossUrl = res.data;
          if(this.urlType == 'img') {
            this.editorModule.execCommand('inserthtml', '<img src="'+ ossUrl +'" width="600"/>');
          } else if(this.urlType == 'video') {

          }
        }
      })
    },
  }
}
</script>

<style lang='less'>
  .addOperational {
    background-color: #fff;
    padding: 28px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .eltabs {
      width: 900px;
    }
    .demo-ruleForm {
      width: 900px;
      // .edui-editor-iframeholder {
      //   height: 500px!important;
      // }
      .art-tips {
        font-size: 14px;
        color: red;
      }
    }
    .opera-btn {
      text-align: center;
      .el-button {
        margin-left: 798px;
      }
    }
  }
</style>